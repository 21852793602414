import { default as contactsQYYeY57YFdMeta } from "/workspace/pages/contacts.vue?macro=true";
import { default as designT4UGtSao0pMeta } from "/workspace/pages/design.vue?macro=true";
import { default as illustrationcW5rU5GRWDMeta } from "/workspace/pages/illustration.vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as loginGeKhs9qf7uMeta } from "/workspace/pages/login.vue?macro=true";
import { default as editFi47q8bvgyMeta } from "/workspace/pages/product/[id]/edit.vue?macro=true";
import { default as indexklxuIpzlZQMeta } from "/workspace/pages/product/[id]/index.vue?macro=true";
import { default as addLeB8kozXVmMeta } from "/workspace/pages/product/add.vue?macro=true";
import { default as thank_45youeGzjVBwbMFMeta } from "/workspace/pages/thank-you.vue?macro=true";
export default [
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/workspace/pages/contacts.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/workspace/pages/design.vue")
  },
  {
    name: "illustration",
    path: "/illustration",
    component: () => import("/workspace/pages/illustration.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/workspace/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/workspace/pages/login.vue")
  },
  {
    name: "product-id-edit",
    path: "/product/:id()/edit",
    component: () => import("/workspace/pages/product/[id]/edit.vue")
  },
  {
    name: "product-id",
    path: "/product/:id()",
    component: () => import("/workspace/pages/product/[id]/index.vue")
  },
  {
    name: "product-add",
    path: "/product/add",
    component: () => import("/workspace/pages/product/add.vue")
  },
  {
    name: "thank-you",
    path: "/thank-you",
    component: () => import("/workspace/pages/thank-you.vue")
  }
]