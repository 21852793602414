import revive_payload_client_4sVQNw7RlN from "/workspace/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/workspace/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/workspace/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/workspace/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import supabase_client_2ruDq4uEtU from "/workspace/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_yVLowv6hDl from "/workspace/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/workspace/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/workspace/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/workspace/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/workspace/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/workspace/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/workspace/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ghbUAjaD3n from "/workspace/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import components_client_rgwwAZDE5D from "/workspace/node_modules/nuxt-swiper/dist/runtime/plugins/components.client.mjs";
import auth_redirect_bWtzICY6fh from "/workspace/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import sentry_client_VdOeuw25Mb from "/workspace/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/workspace/.nuxt/sentry-client-config.mjs";
import vue_gtag_S6KjvYXIWb from "/workspace/plugins/vue-gtag.js";
import vue_masonry_wall_13R6vu58Yn from "/workspace/plugins/vue-masonry-wall.ts";
import vue_universal_modals_z7FEt3a8vm from "/workspace/plugins/vue-universal-modals.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  supabase_client_2ruDq4uEtU,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_ghbUAjaD3n,
  components_client_rgwwAZDE5D,
  auth_redirect_bWtzICY6fh,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  vue_gtag_S6KjvYXIWb,
  vue_masonry_wall_13R6vu58Yn,
  vue_universal_modals_z7FEt3a8vm
]